import { combineReducers } from 'redux';
import mergeWith from 'lodash/merge';
import session from './session';
import profile from './profile';
import correspondence from './correspondence';
import listings from './listings';
import folders from './folders';
import activity from './activity';
import search from './search';
import transaction from './transaction';
import contacts from './contacts';
import { AUTH_LOGOUT } from '../actions/session';



const defaultEntityState = {
    Activities: {},
    Listings: {},
    //ListingsSearch: {},
    Folders: {},
    activity: {}
};

const entities = (state = defaultEntityState, action) => {
    if (action.response && action.response.entities) {
      return mergeWith({}, state, action.response.entities, (src, dest) => {
        // Deep merge arrays
        if (Array.isArray(src)) {
          return dest;
        }
      });
    }
  
    return state;
  };

const appReducer = combineReducers({
    entities,
    session,
    profile,
    correspondence,
    listings,
    folders,
    activity,
    search,
    transaction,
    contacts
});

const rootReducer = (state, action) => {
    if (action.type === AUTH_LOGOUT) {
        state = undefined;
    }

   return appReducer(state, action);
}


export default rootReducer;
import { CALL_API } from '../store/api';
import qs from 'query-string';

export const PROFILE_REQUEST = 'PROFILE_REQUEST';
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS';
export const PROFILE_FAILURE = 'PROFILE_FAILURE';

export const RECOMMENDED_VENDOR_REQUEST = 'RECOMMENDED_VENDOR_REQUEST';
export const RECOMMENDED_VENDOR_SUCCESS = 'RECOMMENDED_VENDOR_SUCCESS';
export const RECOMMENDED_VENDOR_FAILURE = 'RECOMMENDED_VENDOR_FAILURE';

export const AGENT_PROFILE_REQUEST = 'AGENT_PROFILE_REQUEST';
export const AGENT_PROFILE_SUCCESS = 'AGENT_PROFILE_SUCCESS';
export const AGENT_PROFILE_FAILURE = 'AGENT_PROFILE_FAILURE';

export const PROFILE_UPDATE_REQUEST = 'PROFILE_UPDATE_REQUEST';
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS';
export const PROFILE_UPDATE_FAILURE = 'PROFILE_UPDATE_FAILURE';

export const SETTINGS_SUCCESS = 'SETTINGS_SUCCESS';

export const fetchProfile = () => (dispatch, getState) => {
    return dispatch({
        [CALL_API]: {
            types: [PROFILE_REQUEST, PROFILE_SUCCESS, PROFILE_FAILURE],
            method: 'GET',
            endpoint: `profile`
        }
    });
}

export const fetchMyAgentProfile = () => (dispatch, getState) => {
    return dispatch({
        [CALL_API]: {
            types: [AGENT_PROFILE_REQUEST, AGENT_PROFILE_SUCCESS, AGENT_PROFILE_FAILURE],
            method: 'GET',
            endpoint: `myagentinfo`
        }
    })
}

export const setMyAgentProfile = (agent) => (dispatch) => {
    return dispatch({
        type: AGENT_PROFILE_SUCCESS,
        response: agent
    })
}

export const fetchRecommendedVendors = () => (dispatch, getState) => {
    return dispatch({
        [CALL_API]: {
            types: [RECOMMENDED_VENDOR_REQUEST, RECOMMENDED_VENDOR_SUCCESS, RECOMMENDED_VENDOR_FAILURE],
            method: 'GET',
            endpoint: `recommendedvendors`
        }
    })
}

export const fetchSystemSettingsUrls = () => (dispatch) => {
    return dispatch({
        [CALL_API]: {
            types: ['SETTINGS_REQUEST', SETTINGS_SUCCESS, 'SETTINGS_FAILURE'],
            method: 'GET',
            endpoint: `system/settings`
        }
    })
}

export const fetchPublicSystemSettingsUrls = () => (dispatch) => {
    return dispatch({
        [CALL_API]: {
            types: ['SETTINGS_REQUEST', SETTINGS_SUCCESS, 'SETTINGS_FAILURE'],
            method: 'GET',
            endpoint: 'public/system/settings'
        }
    })
}

export const changeEmail = (paramStr) => (dispatch, getState) => {
    const parsed = qs.parse(paramStr);
    return dispatch({
        [CALL_API]: {
            types: [PROFILE_UPDATE_REQUEST, PROFILE_UPDATE_SUCCESS, PROFILE_UPDATE_FAILURE],
            method: 'POST',
            body: parsed,
            endpoint: `changeEmail`
        }
    })
}

export const changePassword = (paramStr) => (dispatch, getState) => {
    const parsed = qs.parse(paramStr);
    return dispatch({
        [CALL_API]: {
            types: [PROFILE_UPDATE_REQUEST, PROFILE_UPDATE_SUCCESS, PROFILE_UPDATE_FAILURE],
            method: 'POST',
            body: parsed,
            endpoint: `changePassword`
        }
    })
}
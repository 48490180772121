import union from 'lodash/union'
import {
    FOLDERS_REQUEST,
    FOLDERS_SUCCESS,
    FOLDERS_FAILURE,
    RECEIVED_DATES_REQUEST,
    RECEIVED_DATES_SUCCESS,
    RECEIVED_DATES_FAILURE
} from '../actions/folders';

const updatePagination = (state = {
    isFetching: false, 
    searchName: [],
    receivedDates: {}
}, action) => {
  switch (action.type) {
    case FOLDERS_REQUEST:
        return {
            ...state,
            isFetching: true,
            error: undefined
        };
    case FOLDERS_SUCCESS:
        return {
            ...state,
            isFetching: false, 
            searchName: union(action.response.result.Folders),
            error: undefined
        };
    case FOLDERS_FAILURE:
        return {
            ...state,
            isFetching: false,
            error: action.error
        };
        // Received_Dates
    case RECEIVED_DATES_REQUEST:
        return {
            ...state,
            isFetching: true,
            error: undefined
        };
    case RECEIVED_DATES_SUCCESS:
        return {
            ...state,
            isFetching: false,
            //searchName: union(action.response.result.receivedDates),
            receivedDates: {...state.receivedDates, ...action.response},
            error: undefined
        };
    case RECEIVED_DATES_FAILURE:
        return {
            ...state,
            isFetching: false,
            error: action.error
        };
    default:
        return state;
  }
}

const folders = (state = {}, action) => {
    switch (action.type) {
      case FOLDERS_REQUEST:
      case FOLDERS_SUCCESS:
      case FOLDERS_FAILURE:
      case RECEIVED_DATES_REQUEST:
      case RECEIVED_DATES_SUCCESS:
      case RECEIVED_DATES_FAILURE:
        const key = action.key;
        return {
          ...state,
          [key]: updatePagination(state[key], action)
        };
      default:
        return state;
    }
  }
  
  export default folders;
  
  
import { CALL_API } from '../store/api';
import Cookies from 'universal-cookie';

export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_REFRESH_SUCCESS = 'AUTH_REFRESH_SUCCESS';
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';
export const AUTH_FAILURE = 'AUTH_FAILURE';
export const AUTH_MANUAL_LOGIN_SUCCESS = 'AUTH_MANUAL_LOGIN_SUCCESS';
export const AUTH_REFRESH_NOTEXPIRED = 'AUTH_REFRESH_NOTEXPIRED';

const endpoint = 'auth';

export const refreshAuthToken = id => (dispatch, getState) => {
    const cookies = new Cookies();
    const authToken = cookies.get('authDetails');
    var accessTokenFromSession = window.sessionStorage.getItem("APIKey");

    // If both cookie and session aren't found then logout
    if (!authToken && !accessTokenFromSession) return dispatch(logout());

    // If the Session Storage key isn't there then just use the cookie value  
    if (accessTokenFromSession === null || accessTokenFromSession === undefined)
        window.sessionStorage.setItem("APIKey", authToken.access_token);

    return dispatch({
        [CALL_API]: {
            types: [AUTH_REQUEST, AUTH_REFRESH_SUCCESS, AUTH_FAILURE],
            method: 'POST',
            body: {                
            },
            endpoint: `${endpoint}/refresh`
        }
    });
};

export const login = (body) => ({
     [CALL_API]: {
         types: [AUTH_REQUEST, AUTH_LOGIN_SUCCESS, AUTH_FAILURE],
         method: 'POST',
         body: body,
         endpoint: `${endpoint}/login`
     }
});

export const manualLogin = (response) => (dispatch, getState) => {
    return dispatch({
        type: AUTH_MANUAL_LOGIN_SUCCESS,
        response: response
    });
};

export const logout = (expired = false) => (dispatch, getState) => {
    return dispatch({
        type: AUTH_LOGOUT,
        expired
    });
};

export const setAuthError = (errMsg) => (dispatch, getSelection) => {
    return dispatch({
        type: AUTH_FAILURE,
        error: errMsg 
    })
};
 

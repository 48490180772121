import {
  PROFILE_REQUEST,
  PROFILE_SUCCESS,
  PROFILE_FAILURE,
  RECOMMENDED_VENDOR_REQUEST,
  RECOMMENDED_VENDOR_SUCCESS,
  RECOMMENDED_VENDOR_FAILURE,
  AGENT_PROFILE_REQUEST,
  AGENT_PROFILE_SUCCESS,
  AGENT_PROFILE_FAILURE,
  PROFILE_UPDATE_SUCCESS,
  PROFILE_UPDATE_FAILURE,
  SETTINGS_SUCCESS,
} from "../actions/profile";

const profile = (
  state = {
    isFetching: false,
    mine: null,
    agent: null,
    vendors: null,
    settings: null,
    error: undefined,
    notificationMessage: undefined,
  },
  action
) => {
  switch (action.type) {
    case PROFILE_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
        notificationMessage: undefined,
      };
    case PROFILE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        mine: action.response,
        error: undefined,
      };
    case PROFILE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case RECOMMENDED_VENDOR_REQUEST:
      return {
        ...state,
        //isFetchingVendorList: true
      };
    case RECOMMENDED_VENDOR_SUCCESS:
      return {
        ...state,
        //isFetchingVendorList: false,
        vendors: action.response,
      };
    case RECOMMENDED_VENDOR_FAILURE:
      return {
        ...state,
        isFetchingVendorList: false,
        vendors: null,
        vendorError: action.error,
      };
    case AGENT_PROFILE_REQUEST:
      return {
        ...state,
        isFetchingAgentProfile: true,
      };
    case AGENT_PROFILE_SUCCESS:
      return {
        ...state,
        isFetchingAgentProfile: false,
        agent: action.response,
      };
    case AGENT_PROFILE_FAILURE:
      return {
        ...state,
        isFetchingAgentProfile: false,
        agent: null,
        agentError: action.error,
      };
    case PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        notificationMessage: action.response.Success || action.response.Error,
      };
    case PROFILE_UPDATE_FAILURE:
      return {
        ...state,
        notificationMessage: action.Error,
      };
    case SETTINGS_SUCCESS:
      // flatten clientPortalSettings into settings object
      const { clientPortalSettings, ...resp } = action.response;
      return {
        ...state,
        settings: { ...resp, ...clientPortalSettings },
      };
    default:
      return state;
  }
};

export default profile;

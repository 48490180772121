import React from 'react';
import { LoadScript } from '@react-google-maps/api';

const GoogleMapsProvider = ({ children }) => {

  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  
  return <LoadScript googleMapsApiKey={googleMapsApiKey}>{children}</LoadScript>;
};

export default GoogleMapsProvider;

import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './App.css';
import '../scss/App.scss';
import asyncComponent from '../components/AsyncComponent';
import AnonymousNavbar from '../components/anonymousnavbar';
import NotFound404 from '../components/NotFound404';
import { isIframe, removeQueryStringParams } from '../components/utils';
import base64 from 'base-64';
import utf8 from 'utf8';

import { ReactNotifications, Store } from 'react-notifications-component'

import * as sessionActions from '../actions/session';

import 'react-notifications-component/dist/theme.css'
import 'react-day-picker/dist/style.css';
import Loading from '../components/Loading';
import "iframe-resizer/js/iframeResizer.contentWindow.min";
import '../services/apmRum';

const Navbar = asyncComponent(() => import('../components/Navbar'));
const AsyncLoginPage = asyncComponent(() => import("../containers/LoginPage"));
const AsyncLandingPage = asyncComponent(() => import("../containers/LandingPage"));
const AsyncAgentProfile = asyncComponent(() => import("../containers/AgentProfile"));
const AsyncCorrespondenceMobile = asyncComponent(() => import("../containers/CorrespondenceMobile"));
const AsyncRecommendedServices = asyncComponent(() => import("../containers/RecommendedServicesMobile"));
const AsyncDashboardPage = asyncComponent(() => import("../containers/DashboardPage"));
const AsyncActivityWidget = asyncComponent(() => import("./ActivityWidget"));
const AsyncMyListings = asyncComponent(() => import("../containers/MyListingsPage"));
const AsyncMyListingsFiltered = asyncComponent(() => import("../containers/MyListingsPageFiltered"));
const AsyncListingDetails = asyncComponent(() => import("../containers/ListingDetailsPage"));
const AsyncCorrespondence = asyncComponent(() => import("../containers/CorrespondencePage"));
const AsyncSearchPage = asyncComponent(() => import("../containers/SearchPage"));
const AsyncGoogleAnalytics = asyncComponent(() => import("../components/GoogleAnalytics"));

class App extends Component {

  showNotification = (type, title, message, duration = 8000) => {
    Store.addNotification({
      title,
      message,
      type,
      container: "top-center",
      dismiss: {
        duration,
      }
    });
  }

  render() {
    const { session } = this.props;

    return (
      <div className="App">
        <Router>
          <div>
            <ReactNotifications />

            <Switch>
              <Route path="/login" render={(props) =>
                <AsyncLoginPage showNotification={this.showNotification} {...props} />} />

              <Route path="/listing-details/public/:listingId?" exact={true} render={(props) =>
                <div>
                  <Navbar publicPortal showNotification={this.showNotification} />
                  <AsyncListingDetails publicPortal showNotification={this.showNotification} {...props} />
                </div>} />

              <Route path="/shared-link/:permalink/:publicId?" exact={true} render={(props) =>
                <div>
                  <Navbar publicPortal showNotification={this.showNotification} />
                  <AsyncListingDetails publicPortal showNotification={this.showNotification} {...props} />
                </div>} />

              {/* Private Routes that Require Login */}
              <PrivateRoute
                path="/iframe/activity"
                exact={true}
                session={session}
                component={AsyncActivityWidget}
                showNotification={this.showNotification}
              />

              <PrivateRoute
                path="/dashboard"
                exact={true}
                session={session}
                component={AsyncDashboardPage}
                showNotification={this.showNotification}
              />
              <PrivateRoute
                path="/agent-profile"
                exact={true}
                session={session}
                component={AsyncAgentProfile}
                showNotification={this.showNotification}
              />
              <PrivateRoute
                path="/correspondance-mobile"
                exact={true}
                session={session}
                component={AsyncCorrespondenceMobile}
                showNotification={this.showNotification}
              />
              <PrivateRoute
                path="/recommended-services"
                exact={true}
                session={session}
                component={AsyncRecommendedServices}
                showNotification={this.showNotification}
              />
              <PrivateRoute
                path="/my-listings"
                exact={true}
                session={session}
                component={AsyncMyListings}
                showNotification={this.showNotification}
              />
              <PrivateRoute
                path="/listing-details/:listingId"
                exact={true}
                session={session}
                component={AsyncListingDetails}
                showNotification={this.showNotification}
              />
              <PrivateRoute
                path="/my-listings-type/:type/:receivedDate?"
                exact={true}
                session={session}
                component={AsyncMyListingsFiltered}
                showNotification={this.showNotification}
              />
              <PrivateRoute
                path="/favorites-and-comments/:type"
                exact={true}
                session={session}
                component={AsyncMyListingsFiltered}
                showNotification={this.showNotification}
              />
              <PrivateRoute
                path="/correspondence/:type"
                exact={true}
                session={session}
                component={AsyncCorrespondence}
                showNotification={this.showNotification}
              />
              <PrivateRoute
                path="/search/:filters?"
                exact={true}
                session={session}
                component={AsyncSearchPage}
                showNotification={this.showNotification}
              />
              <PrivateRoute
                path="/"
                exact={true}
                session={session}
                component={AsyncLandingPage}
                showNotification={this.showNotification}
              />
              <Route render={(props) =>
                <NotFound404 showNotification={this.showNotification} {...props} />} />
            </Switch>
            <AsyncGoogleAnalytics />
          </div>
        </Router>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {

  return {
    session: state.session,
  }
};

const mapDispatchToProps = dispatch => ({
  sessionActions: bindActionCreators(sessionActions, dispatch)
});


export default connect(mapStateToProps, mapDispatchToProps)(App);

// Redirect to the component only if the user is logged in, otherwise send to login page
const PrivateRoute = ({ component: Component, session, refreshAuth, ...rest }) => (
  <Route {...rest} render={props => {
    const { isAuthenticating, isAuthenticated } = session;

    // We are not Authenticated and there is no open Authentication requests
    // Redirect to login
    if (isAuthenticated === false && isAuthenticating === false) {

      if (props.location && props.location.pathname && props.location.pathname !== '/' && props.location.pathname !== '/profile') {

        const redirectToUrl = removeQueryStringParams("token");
        window.sessionStorage.setItem("RedirectLocation", base64.encode(utf8.encode(redirectToUrl)));
      }

      let searchString = props.location.search;
      if (session.expired) searchString = searchString ? `${searchString}&expired=true` : '?expired=true';
      // Push with query string so user will login
      props.history.push(`/login${searchString}`);
      return null;
    }

    // Waiting on Authentication to complete so display a message
    if (isAuthenticating && isAuthenticated === false) {
      return <Loading />;
    }

    // Return the secure page and the navbar
    return <div>
      {(!isIframe() && session.isAuthenticated && rest && rest.path !== "/iframe/activity") ?
        <Navbar showNotification={rest.showNotification} /> : <AnonymousNavbar />}
      <Component {...props} {...rest} />
    </div>;

  }} />
)
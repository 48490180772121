import { CALL_API, Schemas } from '../store/api';
import { normalize } from 'normalizr';
import { getOffset } from '../components/utils'; 
import qs from 'query-string';
import { v4 as uuidv4 } from 'uuid';

export const LISTINGS_REQUEST = 'LISTINGS_REQUEST';
export const LISTINGS_SUCCESS = 'LISTINGS_SUCCESS';
export const LISTINGS_FAILURE = 'LISTINGS_FAILURE';

export const VISITEDDATE_REQUEST = "VISITEDDATE_REQUEST"; 
export const VISITEDDATE_SUCCESS = "VISITEDDATE_SUCCESS"; 
export const VISITEDDATE_FAILURE = "VISITEDDATE_FAILURE";

export const INTERESTLEVEL_REQUEST = "INTERESTLEVEL_REQUEST"; 
export const INTERESTLEVEL_SUCCESS = "INTERESTLEVEL_SUCCESS"; 
export const INTERESTLEVEL_FAILURE = "INTERESTLEVEL_FAILURE";

export const LISTING_ADD_ENTITIES = 'LISTING_ADD_ENTITIES';

let _controller;

export const fetchSearch = (filters, fetchPage) => (dispatch, getState) => {
    const offset = getOffset(fetchPage, pageSize);
    var sOrder = (filters.Criteria && filters.Criteria.sortOrder) || "Newest";
    const {sortOrder, ...sCriteria} = filters.Criteria; // get all fields but sortOrder

    // for each new request we'll create a new controller and abort older pending requests
    const controller = new AbortController();
    const signal = controller.signal;

    if (_controller !== undefined) {
        _controller.abort();
        _controller = null;
    }

    _controller = controller;
    const requisitionId = uuidv4()
    window.localStorage.setItem('requisitionId', requisitionId);

    return dispatch({
        [CALL_API]: {
            types: [LISTINGS_REQUEST, LISTINGS_SUCCESS, LISTINGS_FAILURE],
            method: 'POST',
            body: {Criteria: sCriteria, requisitionId},
            endpoint: `listings/filter?offset=${offset}&limit=${pageSize}&sortOrder=${sOrder}`,
            schema: Schemas.LISTING_ARRAY,
            page: fetchPage || 1,
            options: { signal }
        },
        key: 'search',
        isNewSearch: typeof fetchPage === 'undefined' // filters changed - reset pages
    })
};

export const fetchFavorites = (key, sortOrder, fetchPage, isNewSearch) => (dispatch, getState) => {
    const offset = getOffset(fetchPage, pageSize);
    var params = {
        sortorder: sortOrder
    }
    var queryString = qs.stringify(params)
    queryString = queryString.length ? "&" + queryString : ""; 
    return dispatch({
        [CALL_API]: {
            types: [LISTINGS_REQUEST, LISTINGS_SUCCESS, LISTINGS_FAILURE],
            method: 'GET',
            endpoint: `my${key}?offset=${offset}&limit=${pageSize}${queryString}`,
            schema: Schemas.LISTING_ARRAY,
            page: fetchPage || 1 
        },
        key: key,
        isNewSearch: isNewSearch
    })
};

export const fetchMyAgentListings = () => ({
    [CALL_API]: {
        types: [LISTINGS_REQUEST, LISTINGS_SUCCESS, LISTINGS_FAILURE],
        method: 'GET',
        endpoint: `listingsListedByAgent`,
        schema: Schemas.LISTING_ARRAY
    },
    key: 'myAgent'
});

export function searchNameSelector(key) {
    if (key === 'new' || key === 'undecided' || key === 'favorites' || key === 'rejected')
        return key;
    else return undefined;
}
export function searchIdSelector(key) {
    if (key === 'new' || key === 'undecided' || key === 'all' || key === 'favorites' || key === 'rejected')
        return undefined;
    else return key;
}
// method called for dashboard counts and everything on MyListingsPageFiltered, 
// including favorites/maybe/comments/rejected, which they have their own endpoint they get routed to.
// this method takes either searchid or searchname, because folders and above keys used to be in the same drop down.
export const fetchAgentListingsByFolder = (key, receivedDate, query, fetchPage, isNewSearch) => (dispatch, getState) => {
    if (['favorites','maybes','comments','rejected'].includes(key)) 
        return dispatch(fetchFavorites(key, query.sortorder, fetchPage, isNewSearch));

    var params = {
        searchname: searchNameSelector(key),
        searchid: searchIdSelector(key),
        receiveddateid: receivedDate,
        ...query
    }
    var queryString = qs.stringify(params, { arrayFormat: 'comma' });
    queryString = queryString.length ? "&" + queryString : ""; 
    const offset = getOffset(fetchPage, pageSize);
    
    return dispatch({
        [CALL_API]: {
            types: [LISTINGS_REQUEST, LISTINGS_SUCCESS, LISTINGS_FAILURE],
            method: 'GET',
            endpoint: `mylistingsfromagent?offset=${offset}&limit=${pageSize}${queryString}`,
            schema: Schemas.LISTING_ARRAY,
            page: fetchPage || 1 
        },
        key: key,
        isNewSearch: isNewSearch
    })
};

export const fetchListingById = (id) => ({
    [CALL_API]: {
        types: [LISTINGS_REQUEST, LISTINGS_SUCCESS, LISTINGS_FAILURE],
        method: 'POST',
        endpoint: `listing/${id}`,
        schema: Schemas.SINGLE_LISTING
    },
    key: 'activityListing'
});

// for details report, includes report fields, docs, etc
export const fetchListingDetailsById = (id) => ({
    [CALL_API]: {
        types: [LISTINGS_REQUEST, LISTINGS_SUCCESS, LISTINGS_FAILURE],
        method: 'POST',
        endpoint: `listingdetails/${id}`,
        schema: Schemas.SINGLE_LISTING
    },
    key: 'detailsReport'
});

export const visitedDateSave = (propertyId, visitedDate) => ({
    [CALL_API]: {
        types: [VISITEDDATE_REQUEST, VISITEDDATE_SUCCESS, VISITEDDATE_FAILURE],
        method: 'POST',
        endpoint: `visited/save?PropertyId=${propertyId}&VisitedDate=${visitedDate}`
    }
});

export const visitedDateDelete= (propertyId, visitedDate) => ({
    [CALL_API]: {
        types: [VISITEDDATE_REQUEST, VISITEDDATE_SUCCESS, VISITEDDATE_FAILURE],
        method: 'POST',
        endpoint: `visited/delete?PropertyId=${propertyId}&VisitedDate=${visitedDate}`
    }
});

export const interestLevelSave = (propertyId, interestLevel) => ({
    [CALL_API]: {
        types: [INTERESTLEVEL_REQUEST, INTERESTLEVEL_SUCCESS, INTERESTLEVEL_FAILURE],
        method: 'POST',
        endpoint: `favorite/interest?PropertyId=${propertyId}&InterestLevel=${interestLevel}`
    }
});

export const addListingToEntities = (listing) => (dispatch, getState) => {
    const response = normalize({Listings: [listing]} , Schemas.LISTING_ARRAY);
    return dispatch({type: LISTING_ADD_ENTITIES, response: response})
}

export const pageSize = 100;
import { CALL_API, Schemas } from '../store/api';

export const CORRESPONDENCE_REQUEST = 'CORRESPONDENCE_REQUEST';
export const CORRESPONDENCE_SUCCESS = 'CORRESPONDENCE_SUCCESS';
export const CORRESPONDENCE_FAILURE = 'CORRESPONDENCE_FAILURE';
export const CORRESPONDENCE_COUNT_SUCCESS = 'CORRESPONDENCE_COUNT_SUCCESS';

export const fetchCorrespondence = (key, fetchPage) => (dispatch, getState) => {
    const offset = getOffset(fetchPage);
    
    return dispatch({
        [CALL_API]: {
            types: [CORRESPONDENCE_REQUEST, CORRESPONDENCE_SUCCESS, CORRESPONDENCE_FAILURE],
            method: 'GET',
            endpoint: `correspondence/${key}/list?offset=${offset}&limit=${pageSize}`,
            schema: Schemas.CORRESPONDENCE_ARRAY,
            page: fetchPage || 1
 
        },
        key: key
    })
};

export const fetchCorrespondenceCount = (key) => (dispatch, getState) => {
    return dispatch({
        [CALL_API]: {
            types: [CORRESPONDENCE_REQUEST, CORRESPONDENCE_COUNT_SUCCESS, CORRESPONDENCE_FAILURE],
            method: 'GET',
            endpoint: `correspondence/${key}`
        },
        key: key
    })
};



export const pageSize = 10;

const getOffset = (fetchPage) => {
    var page = 1;
    if (fetchPage) page = fetchPage;
    const offset = page === 1 ? 0 : (page - 1) * pageSize + 1;
    return offset;
};
import {init as RumAgent} from '@elastic/apm-rum';

const isDevEnvironment = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
const apmRum = RumAgent({
    serviceName: 'clientportal_ui',
    serverUrl: process.env.REACT_APP_ELASTIC_APM_SERVER_URL,
    environment: process.env.REACT_APP_ELASTIC_APM_ENVIRONMENT,
    active: !isDevEnvironment
});

export default apmRum;
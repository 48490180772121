import { CALL_API, Schemas } from '../store/api';

export const CONTACTS_REQUEST = "CONTACTS_REQUEST"; 
export const CONTACTS_SUCCESS = "CONTACTS_SUCCESS"; 
export const CONTACTS_FAILURE = "CONTACTS_FAILURE";

export const fetchContacts = () => (dispatch, getState) => {
    return dispatch({
        [CALL_API]: {
            types: [CONTACTS_REQUEST, CONTACTS_SUCCESS, CONTACTS_FAILURE],
            method: 'GET',
            endpoint: `contacts/list`,
            schema: Schemas.CONTACT_ARRAY,
        }
    });
}
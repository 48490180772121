import union from 'lodash/union'
import {
    CORRESPONDENCE_REQUEST,
    CORRESPONDENCE_SUCCESS,
    CORRESPONDENCE_FAILURE,
    CORRESPONDENCE_COUNT_SUCCESS
} from '../actions/correspondence';

const updatePagination = (state = {
    isFetching: false, 
    ids: [],
    pages: {},
    totalCount: 0
}, action, key) => {
  switch (action.type) {
    case CORRESPONDENCE_REQUEST:
        return {
            ...state,
            isFetching: true,
            error: undefined
        };
    case CORRESPONDENCE_SUCCESS:
        var results = action.response.result.Correspondence;     

        return {
            ...state,
            isFetching: false, 
            ids: union(state.ids, results),
            totalCount: action.response.result.TotalCount,
            //page: state.page + 1,
            pages: {...state.pages, [action.page] : results},
            error: undefined
        };
    case CORRESPONDENCE_COUNT_SUCCESS:
      return action.response;
    case CORRESPONDENCE_FAILURE:
        return {
            ...state,
            isFetching: false,
            error: action.error
        };
    default:
        return state;
  }
}

const correspondence = (state = {}, action) => {
    switch (action.type) {
      case CORRESPONDENCE_REQUEST:
      case CORRESPONDENCE_SUCCESS:
      case CORRESPONDENCE_FAILURE:
      case CORRESPONDENCE_COUNT_SUCCESS:
        const key = action.key;
        return {
          ...state,
          [key]: updatePagination(state[key], action, key)
        };
      default:
        return state;
    }
  }
  
  export default correspondence;
  
  
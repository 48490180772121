import React from "react";
import { Spinner } from "reactstrap";

const Loading = ({ height = "100vh" }) => {
  return (
    <div className="d-flex align-items-center" style={{ height }}>
      <div className="container-fluid pd-lt-rt-0">
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        <span className="ml-2">Loading...</span>
      </div>
    </div>
  );
};

export default Loading;

import Cookies from "universal-cookie";
import Moment from "moment";
import { isIframe } from "../components/utils";

import {
  AUTH_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_REFRESH_SUCCESS,
  AUTH_LOGOUT,
  AUTH_FAILURE,
  AUTH_MANUAL_LOGIN_SUCCESS,
  AUTH_REFRESH_NOTEXPIRED,
} from "../actions/session";

let cookies;

const setSessionItems = (resp) => {
  window.sessionStorage.setItem("APIKey", resp.access_token);
  window.sessionStorage.setItem("LastAuthCheck", new Date());
};

const setCookieItems = (resp) => {
  // Set long term cookie
  cookies = new Cookies();

  // Do not set the cookie if we are in an iframe
  if (isIframe()) {
    cookies.remove("authDetails");
    return;
  }

  var expirationTime = Moment()
    .add(resp.expires_in - 30, "seconds")
    .toDate();
  cookies.set("authDetails", {...resp, expirationTime}, { path: "/", expires: expirationTime });
};

const session = (
  state = {
    isAuthenticating: false,
    isAuthenticated: false,
    user: null,
    audience: {},
    error: undefined,
    expired: false,
  },
  action
) => {
  switch (action.type) {
    case AUTH_REQUEST:
      return {
        ...state,
        isAuthenticating: true,
        error: undefined,
      };
    case AUTH_MANUAL_LOGIN_SUCCESS:
    case AUTH_LOGIN_SUCCESS:
      setSessionItems(action.response);

      setCookieItems(action.response);

      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: true,
        user: action.response,
        // audience: {
        //     type: action.response.AudienceType === 'CLIENT' ? 'client' : 'agent',
        //     ...action.response.Audience
        // },
        error: undefined,
      };
    case AUTH_REFRESH_SUCCESS:
      setSessionItems(action.response);
      setCookieItems(action.response);

      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: true,
        audience: {
          ...state.audience,
          type: action.response.AudienceType === "CLIENT" ? "client" : "agent",
          ...action.response.Audience,
        },
        error: undefined,
      };
    case AUTH_REFRESH_NOTEXPIRED:
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: true,
        error: undefined,
      };
    case AUTH_LOGOUT:
      cookies = new Cookies();
      cookies.remove("authDetails");

      sessionStorage.clear();

      return {
        ...state,
        isAuthenticated: false,
        user: null,
        error: undefined,
        expired: action.expired ?? false
      };
    case AUTH_FAILURE:
      return {
        ...state,
        isAuthenticating: false,
        // TODO If the Auth Refresh fails do we want to signout the user?
        //isAuthenticated: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default session;

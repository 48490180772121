export const contactActivityCustomStyles = {
    control: styles => ({
      ...styles,
      backgroundColor: 'white',
      cursor: 'pointer',
      minHeight: '22px',
      height: '22px',
      width: '140px',
      marginLeft: '8px',
      boxShadow: '0 !important',
      border: "1px solid #B9C8D1",
      "&:hover": {
        border: "1px solid #B9C8D1"
      }
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        cursor: 'pointer',
        borderBottom: data.isLast ? '0' : '1px solid #B9C8D1;',
        backgroundColor: !isSelected ? '#fff' : null,
        color: '#555555',
        padding: '10px',
        fontFamily: 'Inter UI',
        ':active': {
          ...styles[':active'],
          textDecoration: 'underline',
          backgroundColor: '#fff'
        },
        ':hover': {
          ...styles[':hover'],
          textDecoration: 'underline',
          backgroundColor: !isSelected ? '#fff' : null
        },
      };
    },
    placeholder: styles => ({ ...styles, color: '#212529', fontSize: '14px' }),
    singleValue: (styles, { data }) => ({ ...styles, fontSize: '14px', color: '#212529' }),
    dropdownIndicator: styles => ({ ...styles, height: '20px', padding: '0px' }),
    indicatorSeparator: styles => ({ ...styles, display: 'none' }),
    valueContainer: styles => ({ ...styles, padding: '0px 8px', height: '20px', position: 'static' }),
    menu: styles => ({ ...styles, width: '220px', textAlign: 'left', fontSize: '14px', fontWeight: '400', padding: '0.5rem 0.75rem' }),
  };
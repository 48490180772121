import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import api from './api';
import rootReducer from '../reducers';

let middleware = [thunk, api];

const composeEnhancers = process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

export default function configureStore(initialState) {
    const store = createStore(
        rootReducer,
        initialState,
        composeEnhancers(applyMiddleware(...middleware))
    );

    return store;
}

import {
  CONTACTS_REQUEST,
  CONTACTS_SUCCESS,
  CONTACTS_FAILURE,
} from "../actions/contacts";

const contacts = (
  state = {
    isFetching: false,
    ids: [],
    error: undefined,
  },
  action
) => {
  switch (action.type) {
    case CONTACTS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case CONTACTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ids: action.response.result.Contacts,
        error: undefined,
      };
    case CONTACTS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default contacts;

import {
  TRANSACTIONS_REQUEST,
  TRANSACTIONS_SUCCESS,
  TRANSACTIONS_FAILURE,
} from "../actions/transaction";

const transaction = (
  state = {
    isFetching: false,
    transactions: [],
    error: undefined,
  },
  action
) => {
  switch (action.type) {
    case TRANSACTIONS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case TRANSACTIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        transactions: action.response.result.Transactions,
        error: undefined,
      };
    case TRANSACTIONS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default transaction;



import React from 'react';
import {ReactComponent as NotFound} from '../images/svg_icons/404.svg'
import { Link } from 'react-router-dom';

class NotFound404 extends React.Component {
  render() {
    return (<div style={{ marginTop: '168px' }} className="ft-sz-32">
      <NotFound alt="not-found" className="mg-btm-40" />
      <div className="ft-bold">Oops, that address is not on the map</div>
      <div className="mg-btm-40">Let’s get you back on track.</div>
      <div className="ft-sz-20 mg-btm-40">
        <Link to="/search" className="ft-sz-20">Search for homes</Link>, or
        <Link to="/" className="ft-sz-20"> go back to the homepage</Link>
      </div>
    </div>)
  }
}

export default NotFound404;
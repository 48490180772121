import Moment from "moment";
import jwt_decode from "jwt-decode";
import { contactActivityCustomStyles } from "./customStyles";

const isTokenExpired = (token) => {
    const decoded = token && jwt_decode(token)
    try {
        return Moment(decoded.exp * 1000).isBefore(Moment())
    } catch (error) {
        return false
    }
}

export {isTokenExpired, contactActivityCustomStyles}

import React from 'react';
import '../scss/_navbar.scss';

class AnonymousNavbar extends React.Component {
  
    render() {
      return (
        <div></div>
      );
    }
}


export default AnonymousNavbar;
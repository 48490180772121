import union from 'lodash/union'
import {
    LISTINGS_REQUEST,
    LISTINGS_SUCCESS,
    LISTINGS_FAILURE,
    VISITEDDATE_REQUEST,
    VISITEDDATE_SUCCESS,
    VISITEDDATE_FAILURE,
    INTERESTLEVEL_REQUEST,
    INTERESTLEVEL_SUCCESS,
    INTERESTLEVEL_FAILURE
} from '../actions/listings';

const updatePagination = (state = {
    isFetching: false, 
    ids: [],
    pages: {},
    totalCount: 0
}, action, key) => {
  switch (action.type) {
    case LISTINGS_REQUEST:
        return {
            ...state,
            isFetching: true,
            error: undefined
        };
    case LISTINGS_SUCCESS:
        var ids;
        if (key === "search")
            ids = action.response.result.Listings;
        else
            ids = union(state.ids, action.response.result.Listings);
        const responseRequisitionID = action?.response?.result?.requisitionId;
        const hasRequisitionID = Boolean(responseRequisitionID);

        const newState = {
            ...state,
            isFetching: false, 
            ids: ids,
            totalCount: action.response.result.TotalCount,
            pages: action.isNewSearch ? {[action.page] : action.response.result.Listings} :
                {...state.pages, [action.page] : action.response.result.Listings},
            error: undefined
        };

        if(!hasRequisitionID) return newState

        return window.localStorage.getItem('requisitionId') === responseRequisitionID ? newState : { ...state };
    case LISTINGS_FAILURE:
        return {
            ...state,
            isFetching: false,
            error: action.error
        };
    case VISITEDDATE_REQUEST:
    case VISITEDDATE_SUCCESS:
    case VISITEDDATE_FAILURE:
    case INTERESTLEVEL_REQUEST:
    case INTERESTLEVEL_SUCCESS:
    case INTERESTLEVEL_FAILURE:
      return {
        ...state,
        isFetching: false
      };
    default:
        return state;
  }
}

const listings = (state = {}, action) => {
    switch (action.type) {
        case LISTINGS_REQUEST:
        case LISTINGS_SUCCESS:
        case LISTINGS_FAILURE:
        case VISITEDDATE_REQUEST:
        case VISITEDDATE_SUCCESS:
        case VISITEDDATE_FAILURE:
        case INTERESTLEVEL_REQUEST:
        case INTERESTLEVEL_SUCCESS:
        case INTERESTLEVEL_FAILURE:
            const key = action.key;
            return {
                ...state,
                [key]: updatePagination(state[key], action, key)
            };
        default:
            return state;
    }
}

  export default listings;
  
  
import { CALL_API } from '../store/api';

export const UPDATE_SEARCH_PARAMS_REQUEST = 'UPDATE_SEARCH_PARAMS_REQUEST';
export const RESET_SEARCH_PARAMS_REQUEST = 'RESET_SEARCH_PARAMS_REQUEST';

export const SEARCH_CONFIG_REQUEST = "SEARCH_CONFIG_REQUEST"; 
export const SEARCH_CONFIG_SUCCESS = "SEARCH_CONFIG_SUCCESS"; 
export const SEARCH_CONFIG_FAILURE = "SEARCH_CONFIG_FAILURE";

export const AUTOCOMPLETE_REQUEST = "AUTOCOMPLETE_REQUEST"; 
export const AUTOCOMPLETE_SUCCESS = "AUTOCOMPLETE_SUCCESS"; 
export const AUTOCOMPLETE_FAILURE = "AUTOCOMPLETE_FAILURE";

export const SEARCH_SORT_OPTIONS_REQUEST = "SEARCH_SORT_OPTIONS_REQUEST"; 
export const SEARCH_SORT_OPTIONS_SUCCESS = "SEARCH_SORT_OPTIONS_SUCCESS"; 
export const SEARCH_SORT_OPTIONS_FAILURE = "SEARCH_SORT_OPTIONS_FAILURE";

export const MY_SEARCHES_SUCCESS = "MY_SEARCHES_SUCCESS";
export const SAVE_SEARCHES_SUCCESS = "SAVE_SEARCHES_SUCCESS";

export const updateSearchFilters = (filters) => (dispatch, getState) => {
    return dispatch({type: UPDATE_SEARCH_PARAMS_REQUEST, filters: filters})
}

export const resetSearchFilters = (defaultPropertyTypes) => (dispatch, getState) => {
    return dispatch({type: RESET_SEARCH_PARAMS_REQUEST, defaultPropertyTypes: defaultPropertyTypes})
}

export const fetchSearchConfig = () => (dispatch, getState) => {
    return dispatch({
        [CALL_API]: {
            types: [SEARCH_CONFIG_REQUEST, SEARCH_CONFIG_SUCCESS, SEARCH_CONFIG_FAILURE],
            method: 'GET',
            endpoint: 'listings/filter/config',            
        }
    });
};

export const fetchAutoCompleteSuggestions = (query) => ({
    [CALL_API]: {
        types: [AUTOCOMPLETE_REQUEST, AUTOCOMPLETE_SUCCESS, AUTOCOMPLETE_FAILURE],
        method: 'GET',        
        endpoint: `listings/terms?term=${query}`
    },
    query: query
});

export const fetchSearchSortOptions = () => (dispatch, getState) => {
    return dispatch({
        [CALL_API]: {
            types: [SEARCH_SORT_OPTIONS_REQUEST, SEARCH_SORT_OPTIONS_SUCCESS, SEARCH_SORT_OPTIONS_FAILURE],
            method: 'GET',
            endpoint: 'listings/sortOptions',            
        }
    });
};

export const fetchMySearches = (id) => (dispatch, getState) => { 
    return dispatch({
        [CALL_API]: {
            types: ['MY_SEARCHES_REQUEST', MY_SEARCHES_SUCCESS, 'MY_SEARCHES_FAIULRE'],
            method: 'GET',
            endpoint: 'listings/filter/saved',            
        }
    });
}

export const saveSearch = (body) => (dispatch, getState) => { 
    const postBody = { Criteria: body.criteria };
    return dispatch({
        [CALL_API]: {
            types: ['SAVE_SEARCHES_REQUEST', SAVE_SEARCHES_SUCCESS, 'SAVE_SEARCHES_FAILURE'],
            method: 'POST',
            body: postBody,
            endpoint: 'listings/filter/save?searchName=' + body.name + (body.id ? `&id=${body.id}` : '')
        }
    })
}

export const savePrevious = (body) => (dispatch, getState) => { 
    return dispatch({
        [CALL_API]: {
            types: ['SAVE_SEARCHES_REQUEST', SAVE_SEARCHES_SUCCESS, 'SAVE_SEARCHES_FAILURE'],
            method: 'POST',
            body: body,
            endpoint: 'listings/filter/save/previous'
        }
    })
}

export const deleteSearch = (id) => (dispatch, getState) => { 
    return dispatch({
        [CALL_API]: {
            types: ['SAVE_SEARCHES_REQUEST', SAVE_SEARCHES_SUCCESS, 'SAVE_SEARCHES_REQUEST'],
            method: 'POST',
            endpoint: 'listings/filter/delete?id=' + id 
        }
    })
}
import union from 'lodash/union'
import {
    ACTIVITY_REQUEST,
    ACTIVITY_SUCCESS,
    ACTIVITY_FAILURE,
    ADD_COMMENT_REQUEST,
    ADD_COMMENT_SUCCESS,
    ADD_COMMENT_FAILURE,
    UPDATE_COMMENT_REQUEST,
    UPDATE_COMMENT_SUCCESS,
    UPDATE_COMMENT_FAILURE,
    DELETE_COMMENT_REQUEST,
    DELETE_COMMENT_SUCCESS,
    DELETE_COMMENT_FAILURE,
    SINGLE_ACTIVITY_REQUEST,
    SINGLE_ACTIVITY_SUCCESS,
    SINGLE_ACTIVITY_FAILURE

} from '../actions/activity';


const updatePagination = (state = {
    isFetching: false,
    ids: [],
    endOfFeed: false,
    error: undefined,
    isCommentAdding: false,
    isCommentAdded: false,
    isCommentUpdating: false,
    isCommentUpdated: false,
    isCommentDeleting: false,
    isCommentDeleted: false,
    isSAFetching: false,
}, action) => {
  switch (action.type) {
    case ACTIVITY_REQUEST:
        return {
            ...state,
            isFetching: true,
            error: undefined
        };
    case ACTIVITY_SUCCESS:
        var endOfFeed = false;

        // Make sure the limit in actions\activity.js is more than this number
        if (action.response.result.Activities.length < 5) {
            // Check for end of feed
            endOfFeed = true;
        }

        return {
            ...state,
            isFetching: false,
            endOfFeed:endOfFeed,
            ids: action.isNewSearch ? action.response.result.Activities : union(state.ids, action.response.result.Activities),
            error: undefined
        };
    case ACTIVITY_FAILURE:
        return {
            ...state,
            isFetching: false,
            error: action.error
        };
    case SINGLE_ACTIVITY_REQUEST:
        return {
            ...state,
            isSAFetching: true,
            error: undefined
        };
    case SINGLE_ACTIVITY_SUCCESS:
        return {
            ...state,
            isSAFetching: false,
            ids: union(state.ids, action.response.result.Activity),
            error: undefined
        };
    case SINGLE_ACTIVITY_FAILURE:
        return {
            ...state,
            isSAFetching: false,
            error: action.error
        };
    case ADD_COMMENT_REQUEST:
        return {
            ...state,
            isCommentAdding: true,
            isCommentAdded: false,
            error: undefined
        };
    case ADD_COMMENT_SUCCESS:
        return {
            ...state,
            isCommentAdding: false,
            isCommentAdded: true,
            error: undefined
        };
    case ADD_COMMENT_FAILURE:
        return {
            ...state,
            isCommentAdding: false,
            isCommentAdded: false,
            error: action.error
        };
    case UPDATE_COMMENT_REQUEST:
        return {
            ...state,
            isCommentUpdating: true,
            isCommentUpdated: false,
            error: undefined
        };
    case UPDATE_COMMENT_SUCCESS:
        return {
            ...state,
            isCommentUpdating: false,
            isCommentUpdated: true,
            error: undefined
        };
    case UPDATE_COMMENT_FAILURE:
        return {
            ...state,
            isCommentUpdating: false,
            isCommentUpdated: false,
            error: action.error
        };
    case DELETE_COMMENT_REQUEST:
        return {
            ...state,
            isCommentDeleting: true,
            isCommentDeleted: false,
            error: undefined
        };
    case DELETE_COMMENT_SUCCESS:
        return {
            ...state,
            isCommentDeleting: false,
            isCommentDeleted: true,
            error: undefined
        };
    case DELETE_COMMENT_FAILURE:
        return {
            ...state,
            isCommentDeleting: false,
            isCommentDeleted: false,
            error: action.error
        };
    default:
        return state;
  }
};

const activity = (state = {}, action) => {
    switch (action.type){
        case ACTIVITY_REQUEST:
        case ACTIVITY_SUCCESS:
        case ACTIVITY_FAILURE:
        case ADD_COMMENT_REQUEST:
        case ADD_COMMENT_SUCCESS:
        case ADD_COMMENT_FAILURE:
        case UPDATE_COMMENT_REQUEST:
        case UPDATE_COMMENT_SUCCESS:
        case UPDATE_COMMENT_FAILURE:
        case DELETE_COMMENT_REQUEST:
        case DELETE_COMMENT_SUCCESS:
        case DELETE_COMMENT_FAILURE:
        case SINGLE_ACTIVITY_REQUEST:
        case SINGLE_ACTIVITY_SUCCESS:
        case SINGLE_ACTIVITY_FAILURE:
            const key = action.key;

            return {
                ...state,
                [key]: updatePagination(state[key], action, key)
            };
        default:
            return state;
    }
}

export default activity;
import { CALL_API, Schemas } from '../store/api';
import {searchNameSelector, searchIdSelector } from './listings';
import qs from 'query-string';

export const FOLDERS_REQUEST = 'FOLDERS_REQUEST';
export const FOLDERS_SUCCESS = 'FOLDERS_SUCCESS';
export const FOLDERS_FAILURE = 'FOLDERS_FAILURE';

export const RECEIVED_DATES_REQUEST = 'RECEIVED_DATES_REQUEST';
export const RECEIVED_DATES_SUCCESS = 'RECEIVED_DATES_SUCCESS';
export const RECEIVED_DATES_FAILURE = 'RECEIVED_DATES_FAILURE';

export const fetchFolders = () => ({
    [CALL_API]: {
        types: [FOLDERS_REQUEST, FOLDERS_SUCCESS, FOLDERS_FAILURE],
        method: 'GET',
        endpoint: `listingfoldersandnewcount`,
        schema: Schemas.FOLDER_ARRAY
    },
    key: 'folders'
});

export const fetchReceivedDates = (tag) => (dispatch) => {
    var params = {
        searchname: searchNameSelector(tag),
        searchid: searchIdSelector(tag)
    }
    var queryString = qs.stringify(params)
    queryString = queryString.length ? "?" + queryString : ""; 
    return dispatch({
        [CALL_API]: {
            types: [RECEIVED_DATES_REQUEST, RECEIVED_DATES_SUCCESS, RECEIVED_DATES_FAILURE],
            method: 'GET',
            endpoint: `listingsreceiveddates${queryString}`,
            schema: Schemas.RECEIVED_DATES
        },
        key: 'receivedDates'
    })
};
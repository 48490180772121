import { CALL_API, Schemas } from '../store/api';
import { normalize } from 'normalizr';
import qs from 'query-string';
import Moment from 'moment';

export const ACTIVITY_REQUEST = 'ACTIVITY_REQUEST';
export const ACTIVITY_REQUEST_INITIAL = 'ACTIVITY_REQUEST_INITIAL';
export const ACTIVITY_SUCCESS = 'ACTIVITY_SUCCESS';
export const ACTIVITY_FAILURE = 'ACTIVITY_FAILURE';
export const ADD_COMMENT_REQUEST = 'ADD_COMMENT_REQUEST';
export const ADD_COMMENT_SUCCESS = 'ADD_COMMENT_SUCCESS';
export const ADD_COMMENT_FAILURE = 'ADD_COMMENT_FAILURE';
export const UPDATE_COMMENT_REQUEST = 'UPDATE_COMMENT_REQUEST';
export const UPDATE_COMMENT_SUCCESS = 'UPDATE_COMMENT_SUCCESS';
export const UPDATE_COMMENT_FAILURE = 'UPDATE_COMMENT_FAILURE';
export const DELETE_COMMENT_REQUEST = 'DELETE_COMMENT_REQUEST';
export const DELETE_COMMENT_SUCCESS = 'DELETE_COMMENT_SUCCESS';
export const DELETE_COMMENT_FAILURE = 'DELETE_COMMENT_FAILURE';
export const SINGLE_ACTIVITY_REQUEST = 'SINGLE_ACTIVITY_REQUEST';
export const SINGLE_ACTIVITY_SUCCESS = 'SINGLE_ACTIVITY_SUCCESS';
export const SINGLE_ACTIVITY_FAILURE = 'SINGLE_ACTIVITY_FAILURE';


export const fetchActivity = (from, contactId, activityType, activityDate) => (dispatch, getState) => {
    let daysBack = activityDate === 'All' || typeof activityDate === 'undefined' ? undefined :
        new Moment().subtract(activityDate, 'days').startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS');

    var params = {
        limit: !daysBack ? '6' : undefined,
        contactId: contactId,
        from: from,
        activityType: activityType && activityType !== 'AllUpdates' ? activityType : undefined,
        daysBack: daysBack
    }
    return dispatch({
        [CALL_API]: {
            types: [ACTIVITY_REQUEST, ACTIVITY_SUCCESS, ACTIVITY_FAILURE],
            method: 'GET',
            endpoint: `activity?${qs.stringify(params)}`,
            schema: Schemas.ACTIVITY_ARRAY
        },
        key: 'feed',
        isNewSearch: !from // from is set when it's not contactId or activityType change and they are scrolling the feed
    })
};

export const addComment = (body) => ({
    [CALL_API]: {
        types: [ADD_COMMENT_REQUEST, ADD_COMMENT_SUCCESS, ADD_COMMENT_FAILURE],
        method: 'POST',
        body: body,
        endpoint: 'comments/save'
    },
    key: 'feed'
});

export const updateComment = (body) => ({
    [CALL_API]: {
        types: [UPDATE_COMMENT_REQUEST, UPDATE_COMMENT_SUCCESS, UPDATE_COMMENT_FAILURE],
        method: 'POST',
        body: body,
        endpoint: 'comments/update'
    },
    key: 'feed'
});

export const deleteComment = (id) => ({
    [CALL_API]: {
        types: [DELETE_COMMENT_REQUEST, DELETE_COMMENT_SUCCESS, DELETE_COMMENT_FAILURE],
        method: 'POST',
        endpoint: 'comments/delete?Id=' + id
    },
    key: 'feed'
});

export const removeComment = (activity) => (dispatch, getState) => {
    // if you don't set comments to null first normalizr doesn't remove the deleted one
    const nullComments = normalize({Activities: [{...activity, Comments: null}]} , Schemas.ACTIVITY_ARRAY);
    dispatch({type: 'ACTIVITY_COMMENTS_NULL', response: nullComments});

    const response = normalize({Activities: [activity]} , Schemas.ACTIVITY_ARRAY);
    return dispatch({type: 'ACTIVITY_REMOVE_COMMENT', response: response});
}

export const fetchSingleActivity = (id) => ({
    [CALL_API]: {
        types: [SINGLE_ACTIVITY_REQUEST, SINGLE_ACTIVITY_SUCCESS, SINGLE_ACTIVITY_FAILURE],
        method: 'GET',
        endpoint: 'activity/' + id,
        schema: Schemas.ACTIVITY_INDIVIDUAL
    },
    key: 'single'
});


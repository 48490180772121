import { CALL_API, Schemas } from '../store/api';

export const TRANSACTIONS_REQUEST = "TRANSACTIONS_REQUEST"; 
export const TRANSACTIONS_SUCCESS = "TRANSACTIONS_SUCCESS"; 
export const TRANSACTIONS_FAILURE = "TRANSACTIONS_FAILURE";

export const fetchTransactions = () => (dispatch, getState) => {
    return dispatch({
        [CALL_API]: {
            types: [TRANSACTIONS_REQUEST, TRANSACTIONS_SUCCESS, TRANSACTIONS_FAILURE],
            method: 'GET',
            endpoint: `transactions/list`,
            schema: Schemas.TRANSACTION_ARRAY,
        }
    });
}
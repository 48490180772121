import 'react-app-polyfill/ie11';
// import "@babel/polyfill";

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Cookies from 'universal-cookie';
import configureStore from './store';
import './index.css';
import 'bootstrap/dist/css/bootstrap.css';
import App from './containers/App';
import { refreshAuthToken } from './actions/session';
import { fetchProfile, fetchMyAgentProfile, fetchSystemSettingsUrls, fetchPublicSystemSettingsUrls } from './actions/profile';
import { fetchFolders } from './actions/folders';
import { isTokenExpired } from "./utils"
import GoogleMapsProvider from './providers/GoogleMapsProvider';

// Initial redux state
const initialReduxState = {
    activity: { feed: {} }
};

export const store = configureStore(initialReduxState);

// Check if cookie exists
const cookies = new Cookies();
const authToken = cookies.get('authDetails');

// Check if the token is passed in for SSO
var urlParams = new URLSearchParams(window.location.search);
if (urlParams.has('token')) {
    // Update the session token
    window.sessionStorage.setItem("APIKey", urlParams.get('token'));

    // Remove cookie if it exists
    cookies.remove('authDetails');
} else if (authToken && authToken?.access_token) {
    window.sessionStorage.setItem("APIKey", authToken.access_token);
    cookies.remove('authDetails');
}

// Check if session exists
var accessTokenFromSession = window.sessionStorage.getItem("APIKey");

const setup = async () => {
    if (isTokenExpired(accessTokenFromSession)) {
        await store.dispatch(refreshAuthToken());
    } else {
        store.dispatch({
            type: "AUTH_REFRESH_NOTEXPIRED",
        })
    }
    store.dispatch(fetchProfile());
    store.dispatch(fetchMyAgentProfile());
    store.dispatch(fetchFolders());
    store.dispatch(fetchSystemSettingsUrls());
}

// Only reload if token doesn't exist in URL
if ((authToken || accessTokenFromSession)) {
    setup();
} else {
    store.dispatch(fetchPublicSystemSettingsUrls());
}

ReactDOM.render(
    <Provider store={store}>
        <GoogleMapsProvider>
            <App />
        </ GoogleMapsProvider>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
